/* eslint-disable react/button-has-type */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './ripple.module.scss'

function RippleButton({ children, secondary, onClick, ...restButtonProps }) {
  const [ripples, setRipples] = useState([])

  const createRipple = (event) => {
    const { clientX, clientY, currentTarget } = event
    const rect = currentTarget.getBoundingClientRect()
    const size = Math.max(rect.width, rect.height)
    const x = clientX - rect.left - size / 2
    const y = clientY - rect.top - size / 2

    const newRipple = { id: Date.now(), x, y, size }
    setRipples((prev) => [...prev, newRipple])

    setTimeout(() => {
      setRipples((prev) => prev.filter((r) => r.id !== newRipple.id))
    }, 600)
  }

  const handleClick = (event) => {
    createRipple(event)
    onClick(event)
  }

  return (
    <button onClick={handleClick} {...restButtonProps}>
      {children}
      <div className={styles.ripple_container}>
        {ripples.map(({ id, x, y, size }) => (
          <span
            key={id}
            className={c(styles.ripple, secondary && styles['ripple--secondary'])}
            style={{
              width: size,
              height: size,
              left: x,
              top: y,
            }}
          />
        ))}
      </div>
    </button>
  )
}

RippleButton.defaultProps = {
  secondary: false,
  type: 'button',
}

RippleButton.propTypes = {
  secondary: PropTypes.bool,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default RippleButton
