import i18next from 'i18next'
import ApplicantIcon from 'assets/icons/ApplicantIcon'
import {
  APPLICANT_LIST_PATH,
  // APPLICANT_ACTIVE_LIST_PATH,
  // APPLICANT_CANCELLED_LIST_PATH,
  // APPLICANT_FINISHED_LIST_PATH,
} from 'routing/Routes'
import ApplicantRoutes from './ApplicantRoutes'

/**
 * The ReactElement definition.
 *
 * @typedef {import('react').ReactElement} ReactElement
 */

/**
 * The Route definition.
 *
 * @typedef {import('react-router-dom').Route} Route
 */

/**
 * The Submenu properties.
 *
 * @typedef {object} Submenu
 * @property {ReactElement} icon
 * @property {string} display
 * @property {string} id
 * @property {string} routePath
 * @property {boolean} exact
 * @property {boolean} exactQuery
 * @property {object} state
 */

/**
 * The menu properties.
 *
 * @typedef {object} Menu
 * @property {ReactElement} icon
 * @property {string} display
 * @property {string} id
 * @property {Submenu[]} subMenu
 * @property {string} routePath
 * @property {boolean} exact
 * @property {boolean} exactQuery
 * @property {object} state
 */

/**
 * ApplicantScene Plugin module.
 * @module ApplicantScene
 *
 * @param {string} id
 * @param {Route[]} routes
 * @param {Menu} menu
 */
export default {
  id: 'applicant',
  menu: {
    icon: ApplicantIcon,
    display: i18next.t('Applicant:Menu:Applicants'),
    id: 'applicant',
    // subMenu: [
    //   {
    //     display: i18next.t('Applicant:Menu:All'),
    //     id: 'applicantAll',
    //     routePath: APPLICANT_LIST_PATH,
    //     exact: true,
    //     exactQuery: true,
    //     state: {
    //       menu: i18next.t('Applicant:Menu:Applicants'),
    //       subMenu: i18next.t('Applicant:Menu:All'),
    //     },
    //   },
    //   {
    //     display: i18next.t('Applicant:Menu:Active'),
    //     id: 'applicantActive',
    //     routePath: APPLICANT_ACTIVE_LIST_PATH,
    //     exact: true,
    //     exactQuery: true,
    //     state: {
    //       menu: i18next.t('Applicant:Menu:Applicants'),
    //       subMenu: i18next.t('Applicant:Menu:Active'),
    //     },
    //   },
    //   {
    //     display: i18next.t('Applicant:Menu:Cancelled'),
    //     id: 'applicantCancelled',
    //     routePath: APPLICANT_CANCELLED_LIST_PATH,
    //     exact: true,
    //     exactQuery: true,
    //     state: {
    //       menu: i18next.t('Applicant:Menu:Applicants'),
    //       subMenu: i18next.t('Applicant:Menu:Cancelled'),
    //     },
    //   },
    //   {
    //     display: i18next.t('Applicant:Menu:Finished'),
    //     id: 'applicantFinished',
    //     routePath: APPLICANT_FINISHED_LIST_PATH,
    //     exact: true,
    //     exactQuery: true,
    //     state: {
    //       menu: i18next.t('Applicant:Menu:Applicants'),
    //       subMenu: i18next.t('Applicant:Menu:Finished'),
    //     },
    //   },
    // ],
    routePath: APPLICANT_LIST_PATH,
    exact: true,
    exactQuery: false,
    state: {
      menu: i18next.t('Applicant:Menu:Applicants'),
      subMenu: i18next.t('Applicant:Menu:All'),
    },
  },
  routes: ApplicantRoutes,
}
