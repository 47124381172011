import { APPLICANT_LIST_PERMISSION, APPLICANT_VIEW_PERMISSION } from 'permissions'
import { lazy } from 'react'
import { Route } from 'react-router-dom'
import {
  APPLICANT_DETAILS_PATH,
  APPLICANT_LIST_PATH,
  APPLICANT_ACTIVE_LIST_PATH,
  APPLICANT_CANCELLED_LIST_PATH,
  APPLICANT_FINISHED_LIST_PATH,
  // APPLICANT_CREATE_PATH,
} from 'routing/Routes'

/* COMPONENTS */
const ApplicantListScene = lazy(() =>
  import(
    /* webpackChunkName: "ApplicantsListSceneComponent" */
    /* webpackMode: "lazy" */
    /* webpackExports: ["default"] */
    './ApplicantListScene'
  )
)

const ApplicantDetailScene = lazy(() =>
  import(
    /* webpackChunkName: "ApplicantDetailSceneComponent" */
    /* webpackMode: "lazy" */
    /* webpackExports: ["default"] */
    './ApplicantDetailScene'
  )
)

/* ROUTES */
const ApplicantListSceneRoute = () => (
  <Route
    path={APPLICANT_LIST_PATH}
    permission={APPLICANT_LIST_PERMISSION}
    component={ApplicantListScene}
  />
)

const ApplicantActiveListSceneRoute = () => (
  <Route
    exact
    path={APPLICANT_ACTIVE_LIST_PATH}
    permission={APPLICANT_LIST_PERMISSION}
    component={ApplicantListScene}
  />
)

const ApplicantCancelledListSceneRoute = () => (
  <Route
    exact
    path={APPLICANT_CANCELLED_LIST_PATH}
    permission={APPLICANT_LIST_PERMISSION}
    component={ApplicantListScene}
  />
)

const ApplicantFinishedListSceneRoute = () => (
  <Route
    exact
    path={APPLICANT_FINISHED_LIST_PATH}
    permission={APPLICANT_LIST_PERMISSION}
    component={ApplicantListScene}
  />
)

const ApplicantDetailRoute = () => (
  <Route
    exact
    path={APPLICANT_DETAILS_PATH}
    permission={APPLICANT_VIEW_PERMISSION}
    component={ApplicantDetailScene}
  />
)

// const ApplicantCreateRoute = () => (
//   <Route
//     exact
//     path={APPLICANT_CREATE_PATH}
//     permission={APPLICANT_LIST_PERMISSION}
//     component={ApplicantListScene}
//   />
// )

export default [
  ApplicantActiveListSceneRoute,
  ApplicantCancelledListSceneRoute,
  ApplicantFinishedListSceneRoute,
  ApplicantDetailRoute,
  ApplicantListSceneRoute,
]
